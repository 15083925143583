import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';

export default function Head() {
  const translate = useTranslate();

  return (
    <GenericHead
      description={translate(
        'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favorite artists and bands.',
      )}
      noAltLink
      ogType="website"
      socialTitle={translate(
        'Breaking Music News, Celeb and Entertainment News and Gossip',
      )}
      title={translate(
        'Breaking Music News, Celeb and Entertainment News and Gossip',
      )}
      twitterCard="Summary"
    />
  );
}
